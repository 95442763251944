<template>
  <div class="appcointainer" id="bnpformexternal">
    <div class="ht_bnpfv">
      <h2>FORMULARZ KONTAKTOWY</h2>
      <button class="form_swicher" @click="displayForm">&#8645;</button>
    </div>
    <form v-if="showForm" @submit.prevent="onSubmit">
      <div v-if="glonal_error" class="error_msg" id="glonal_error">
        {{ glonal_error }}
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <label for="fname">Imię:</label><br />
          <input v-model="fname" type="text" id="fname" name="fname" /><br />
          <div v-if="fname_error" class="error_msg" id="fname_error">
            {{ fname_error }}
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <label for="lname">Nazwisko:</label><br />
          <input v-model="lname" type="text" id="lname" name="lname" /><br />
          <div v-if="lname_error" class="error_msg" id="lname_error">
            {{ lname_error }}
          </div>
        </div>
      </div>

      <div class="row">
      <div class="col-md-6 col-sm-12">
      <label for="scompany">Firma:</label><br />
      <input
        v-model="scompany"
        type="text"
        id="scompany"
        name="scompany"
      /><br />
      <div v-if="scompany_error" class="error_msg" id="scompany_error">
        {{ scompany_error }}
      </div>
        </div>
        <div class="col-md-6 col-sm-12">
      <label for="scars">Typ samochodu:</label><br />

      <cool-select placeholder="Wybierz" v-model="scars" :items="cartypes" />
      <div v-if="scars_error" class="error_msg" id="scars_error">
        {{ scars_error }}
      </div>
        </div>
      </div>


            <div class="row">
      <div class="col-md-6 col-sm-12">

      <label for="stelefon">Telefon:</label><br />
      <input
        v-model="stelefon"
        type="tel"
        id="stelefon"
        name="stelefon"
      /><br />
      <div v-if="stelefon_error" class="error_msg" id="stelefon_error">
        {{ stelefon_error }}
      </div>
        </div>
        <div class="col-md-6 col-sm-12">
      <label for="semail">E-mail:</label><br />
      <input
        @blur="validateEmail"
        v-model="semail"
        type="email"
        id="semail"
        name="semail"
      /><br />
      <div v-if="semail_error" class="error_msg" id="semail_error">
        {{ semail_error }}
      </div>
      <vue-honeypot ref="honeypot" />
        </div>
      </div>


      <div class="agreements">
        <div class="agrline">
          <div class="agrline_top" :class="{ active: agr_multi }">
            <label for="agr_multi">
              <input
                v-model="agr_multi"
                type="checkbox"
                id="agr_multi"
                name="agr_multi"
              />
              Zaznacz wszystkie
            </label>
          </div>
        </div>

        <div class="agrline">
          <div class="agrline_top" :class="{ active: agr_1 }">
            <label for="agr_1">
              <input v-model="agr_1" type="checkbox" id="agr_1" name="agr_1" />
              Wyrażam zgodę na przetwarzanie przez Arval Service Lease Polska
              sp. z o.o. z siedzibą przy ul. Wołoskiej 24, 02-675 w Warszawie
              moich danych osobowych
            </label>
            <button
              role="button"
              class="agreement-more"
              aria-controls="agreement-more-1"
              @click="showInfo($event)"
            >
              ? <span class="sr-only"> Pełna treść zgody</span>
            </button>
          </div>
          <div class="agreement-more-text" id="agreement-more-1">
            Wyrażam zgodę na przetwarzanie przez Arval Service Lease Polska sp.
            z o.o. z siedzibą przy ul. Wołoskiej 24, 02-675 w Warszawie moich
            danych osobowych w celu i zakresie koniecznym do przedstawienia
            oferty marketingowej produktów i usług własnych.
          </div>
        </div>
        <div class="agrline">
          <div class="agrline_top" :class="{ active: agr_2 }">
            <label for="agr_2">
              <input v-model="agr_2" type="checkbox" id="agr_2" name="agr_2" />
              Wyrażam zgodę na przekazanie przez Arval do Banku BNP Paribas Bank
              Polska S.A. moich danych osobowych oraz informacji
            </label>
            <button
              role="button"
              class="agreement-more"
              aria-controls="agreement-more-2"
              @click="showInfo($event)"
            >
              ? <span class="sr-only"> Pełna treść zgody</span>
            </button>
          </div>
          <div class="agreement-more-text" id="agreement-more-2">
            Wyrażam zgodę na przekazanie przez Arval do Banku BNP Paribas Bank
            Polska S.A. moich danych osobowych oraz informacji dotyczących lub
            związanych z zawartą z Arval umową dot. najmu pojazdu/ów, w celu
            organizacji podpisania umowy oraz rozliczeń wewnętrznych pomiędzy
            Bankiem a Arval.
          </div>
        </div>

        <div class="agrline">
          <div class="agrline_top" :class="{ active: agr_3 }">
            <label for="agr_3">
              <input
                v-model="agr_3"
                type="checkbox"
                id="agr_3"
                name="agr_3"
              />Wyrażam zgodę na przedstawianie w imieniu Arval Service Lease
              Polska Sp. z o.o., drogą telefoniczną informacji dotyczących ofert
              lub promocji produktów
            </label>
            <button
              role="button"
              class="agreement-more"
              aria-controls="agreement-more-3"
              @click="showInfo($event)"
            >
              ? <span class="sr-only"> Pełna treść zgody</span>
            </button>
          </div>
          <div class="agreement-more-text" id="agreement-more-3">
            Wyrażam zgodę na przedstawianie w imieniu Arval Service Lease Polska
            Sp. z o.o., drogą telefoniczną informacji dotyczących ofert lub
            promocji produktów i usług Arval Service Lease Polska Sp. z o.o.,
            zgodnie z ustawą z dnia 16 lipca 2004 r. Prawo telekomunikacyjne
            (tj. Dz.U. 2014 r. poz. 243 z późn. zm.).
          </div>
        </div>

        <div class="agrline">
          <div class="agrline_top" :class="{ active: agr_4 }">
            <label for="agr_4">
              <input v-model="agr_4" type="checkbox" id="agr_4" name="agr_4" />
              Wyrażam zgodę, zgodnie z ustawą z dnia 18 lipca 2002 r. o
              świadczeniu usług marketingowych
            </label>
            <button
              role="button"
              class="agreement-more"
              aria-controls="agreement-more-4"
              @click="showInfo($event)"
            >
              ? <span class="sr-only"> Pełna treść zgody</span>
            </button>
          </div>
          <div class="agreement-more-text" id="agreement-more-4">
            Wyrażam zgodę na otrzymywanie drogą elektroniczną informacji
            dotyczących nowych produktów, usług, promocji Arval Service Lease
            Polska Sp. z o.o. stanowiących informacje handlowe, w tym wiadomości
            SMS. zgodnie z ustawą z dnia 18 lipca 2002 r. o świadczeniu usług
            drogą elektroniczną tj. Dz.U. 2013, poz. 1422, z późn. zm.).
          </div>
        </div>
      </div>

      <AgreementsSection></AgreementsSection>
<div class="recap">
            <vue-recaptcha
        sitekey="6Lep0DgiAAAAAPsNZqBN5mt9SNHd6_f7Pd9oAOgD"
        @verify="onCaptchaVerified"
      ></vue-recaptcha>
</div>
      <div v-if="captcha_error" class="error_msg" id="scars_error">
        {{ captcha_error }}
      </div>
      <div class="submit_area">
        <a
          href="https://www.bnpparibas.pl/repozytorium/rodo-arval"
          target="_blank"
          >Klauzula informacyjna</a
        >
        <button
          :disabled="disabled == 1"
          @click="handleForm"
          class="lead_form_submit_button button"
        >
          {{ sendButtonText }}
        </button>
      </div>



    </form>
  </div>
</template>

<script>
import AgreementsSection from "./components/AgreementsSection";
import axios from "axios";
import { CoolSelect } from "vue-cool-select";
import VueHoneypot from "vue-honeypot";
// eslint-disable-next-line no-unused-vars
import { ref, reactive, defineComponent } from "vue-demi";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  props: ["emailreciver", "sendFrom", "subject"],
  watch: {
    agr_multi: function (newVal) {
      if (newVal == true) {
        this.agr_1 = true;
        this.agr_2 = true;
        this.agr_3 = true;
        this.agr_4 = true;
      } else {
        this.agr_1 = false;
        this.agr_2 = false;
        this.agr_3 = false;
        this.agr_4 = false;
      }
    },
    // emailreciver: function (newVal) {
    //   this.getemailreciver = newVal;
    // },
    // sendFrom: function (newVal) {
    //   this.getsendFrom = newVal;
    // },
  },
  data: function () {
    return {
      glonal_error: "",
      sendButtonText: "Wyślij",
      getsubject: "Temat wiadomości",
      getsendFrom: this.sendFrom ? this.sendFrom : "Nowa wiadomość vue",
      getemailreciver: this.emailreciver
        ? this.emailreciver
        : "no-reply@formularze-arval.pl",
      agr_multi: false,
      agr_1: false,
      agr_2: false,
      agr_3: false,
      agr_4: false,
      cartypes: [
        "Auta małe",
        "Auta miejskie",
        "Coupe",
        "Kabriolet",
        "Kombi",
        "Kompakt",
        "Minivan",
        "Sedan",
        "SUV",
      ],
      selected: null,
      showForm: true,

      fname: "",
      lname: "",
      scompany: "",
      scars: 0,
      stelefon: "",
      semail: "",

      fname_error: "",
      lname_error: "",
      scompany_error: "",
      scars_error: "",
      stelefon_error: "",
      semail_error: "",
      main_error: "",
      disabled: 2,
      recaptchaToken: false,
      captcha_error: false,
    };
  },
  name: "App",
  components: {
    AgreementsSection,
    CoolSelect,
    VueHoneypot,
    VueRecaptcha,
  },
  methods: {
    onCaptchaVerified: function () {
      // console.log(recaptchaToken);
      this.captcha_error = false;
      this.recaptchaToken = true;
    },
    showInfo(e) {
      e.preventDefault();
      let hideshowElement = event.target.getAttribute("aria-controls");
      let element = document.getElementById(hideshowElement);
      element.classList.toggle("show");
    },
    displayForm() {
      if (this.showForm == false) {
        this.showForm = true;
      } else {
        this.showForm = false;
      }
    },
    onSubmit() {
      // console.log("onSubmit");
    },
    validateEmail() {
      // eslint-disable-next-line no-useless-escape
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.semail)) {
        this.semail_error = "";
        return false;
      } else {
        this.semail_error = "Proszę wprowadzić poprawny adres e-mail";
        return true;
      }
    },
    restValidation() {
      if (this.fname == "") {
        this.fname_error = "Proszę wpisać imię";
      } else {
        this.fname_error = "";
      }

      if (this.lname == "") {
        this.lname_error = "Proszę wpisać nazwisko";
      } else {
        this.lname_error = "";
      }

      if (this.scompany == "") {
        this.scompany_error = "Proszę wpisać firmę";
      } else {
        this.scompany_error = "";
      }

      if (this.scars == null) {
        console.log("scars bad");
        this.scars_error = "Proszę wybrać typ samochodu";
      } else {
        this.scars_error = "";
      }
      if (this.semail == "") {
        this.semail_error = "Proszę wpisać adres e-mail";
      } else {
        this.semail_error = "";
      }

      if (this.stelefon == "") {
        this.stelefon_error = "Proszę wpisać numer telefonu";
      } else {
        this.stelefon_error = "";
      }

      if (
        this.fname != "" &&
        this.lname != "" &&
        this.scompany != "" &&
        this.scars != "" &&
        this.semail != "" &&
        this.scars != "" &&
        this.stelefon != ""
      ) {
        return true;
      } else {
        this.glonal_error = "Proszę uzupełnić wymagane pola";
        document.getElementById("bnpformexternal").scrollIntoView();
        return false;
      }
    },
    handleHoneyPot() {
      try {
        this.$refs.honeypot.validate();
        return true;
      } catch (error) {
        console.log("HP!");
        return false;
      }
    },
    handleForm() {
      if (this.recaptchaToken) {
        if (this.restValidation() && this.handleHoneyPot()) {
          this.disabled = 1;
          this.sendButtonText = "Wysyłam...";
          var params = new URLSearchParams();
          params.append("name", this.fname);
          params.append("surname", this.lname);
          params.append("company", this.scompany);
          params.append("cartype", this.scars);
          params.append("phone", this.stelefon);
          params.append("email", this.semail);
          params.append("sendFrom", this.getsendFrom);
          params.append("agr_1", this.agr_1 ? "TAK" : "NIE");
          params.append("agr_2", this.agr_2 ? "TAK" : "NIE");
          params.append("agr_3", this.agr_3 ? "TAK" : "NIE");
          params.append("agr_4", this.agr_4 ? "TAK" : "NIE");

          // settings:

          // sendto - parametr email na który ma zostać wysłana wiadomość
          params.append("sendto", this.getemailreciver);

          const config = {
            responseType: "text",
          };

          axios
            .post("/sender.php", params, config)
            .then((response) => {
              if (response.data.status == "message_sent") {
                let changeme = document.getElementById("bnpformexternal");
                changeme.innerHTML = "<h2>Wiadomość wysłana, dziękujemy!</h2>";
                changeme.scrollIntoView();
              }
            })
            .catch((error) => {
              console.log(error.response);
            });
        }
      } else {
        console.log("UPS!");
        this.captcha_error = "Proszę uzupełnić reCAPTCHA";
      }
    },
  },
  beforeCreate() {},
  created() {},
  mounted: function () {},
};
</script>

<style>
@font-face {
  font-family: "BNPPSans";
  src: url("./fonts/BNPPSans.woff2") format("woff2"),
    url("./fonts/BNPPSans.woff") format("woff"),
    url("./fonts/BNPPSans.svg#BNPPSans") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BNPPSansBold";
  src: url("./fonts/BNPPSans-Bold.woff2") format("woff2"),
    url("./fonts/BNPPSans-Bold.woff") format("woff"),
    url("./fonts/BNPPSans-Bold.svg#BNPPSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "BNPPSansCondensed";
  src: url("./fonts/BNPPSansCondensed.woff2") format("woff2"),
    url("./fonts/BNPPSansCondensed.woff") format("woff"),
    url("./fonts/BNPPSansCondensed.svg#BNPPSansCondensed") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "BNPPSansLight";
  src: url("./fonts/BNPPSans-Light.woff2") format("woff2"),
    url("./fonts/BNPPSans-Light.woff") format("woff"),
    url("./fonts/BNPPSans-Light.svg#BNPPSans-Light") format("svg");
  font-weight: 300;
  font-style: italic;
}

  bnp-vform {
  display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    padding: 20px;
}
.recap {
  display: flex;
    justify-content: flex-end;
    margin-top: 18px;
    margin-bottom: 10px;  
}
.form_swicher {
  display: none;
  color: white;
  background: transparent;
  border: 1px solid white;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-content: center;
  line-height: 17px;
  padding: 2px;
}

.form_swicher:hover {
  cursor: pointer;
}
.error_msg {
  position: relative;
  /* padding-left: 20px; */
  display: flex;
  animation: center;
  align-items: center;
  color: #fff;
  font-family: "BNPPSansLight", Arial, sans-serif;
  font-size: 12px;
}

.error_msg:before {
  color: #fff;
  font-family: "web-font";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  content: "!";
  font-size: 25px;
  display: inline-block;
  left: 0;
  background: #f00f0d;
  width: 20px;
  height: 20px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 20px;
  margin-right: 8px;
}

.agreement-more-text {
}

.agreement-more {
  background: transparent;
  border: 1px solid white;
  color: white;
  border-radius: 25px;
  font-size: 10px;
  min-width: 20px;
  height: 20px;
  margin: 0 0 0 5px;
}
.agreement-more:hover {
  cursor: pointer;
}
.ht_bnpfv {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.submit_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.submit_area a {
  font-size: 18px;
  color: #fff;
  text-decoration: none;
}

.submit_area a:hover {
  color: #fff;
}
.lead_form_submit_button {
  margin-top: 5px;
  border: 1px solid #00834f;
  background: #fff;
  border-color: #fff;
  color: #00834f !important;
  padding: 16px 30px 14px;
  cursor: pointer;
}
.appcointainer {
  background-color: #00834f;
  padding: 28px 24px 24px;
  max-width: 1440px;
  width: 100%;
}
@media all and (max-width: 600px) {
  .appcointainer {
    background-color: #00834f;
    padding: 28px 24px 24px;
  }
}

.appcointainer h2 {
  margin: 0;
  font-size: 40px;
  line-height: 40px;
  font-family: "BNPPSansCondensed", Arial, sans-serif;
  font-weight: 700;
  color: #fff;
}
form {
  margin-top: 30px;
}
label {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
  color: #fff;
  font-family: "BNPPSansLight", Arial, sans-serif;
}
input,
select {
  width: 100%;
  border: 1px solid #c7c7c7;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  padding: 15px 14px;
  font-size: 18px;
  margin-bottom: 5px;
}

.agreements .agrline input {
  /* display: none; */
}

.agreement-more-text {
  display: none;
}
.agreement-more-text.show {
  display: block;
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  font-family: "BNPPSansLight", Arial, sans-serif;
}

.agreements .agrline_top.active {
}
.agreements .agrline_top input {
  display: none;
}
.agreements .agrline_top label {
  display: flex;
}

.agreements .agrline_top {
  display: flex;
  margin-top: 15px;
}
.agreements .agrline_top label:before {
  width: 17px;
  height: 17px;
  border: 3px solid #fff;
  content: "";
  background: white;
  display: inline-block;
  align-self: flex-start;
  min-width: 20px;
  min-height: 20px;
  margin: 0px 10px 0 0;
}

.agreements .agrline_top.active label:before {
  border: 3px solid #fff;
  background: green;
}

.IZ-select .IZ-select__input {
  width: 100%;
  border: 1px solid #c7c7c7;
  border-radius: 2px;
  padding: 15px 14px;
  font-size: 18px;
  margin-bottom: 5px;
}

.IZ-select .IZ-select__input input {
  font-size: 18px;
  padding: 0;
  margin: 0;
}
</style>
