import Vue from 'vue'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element'
import VueSelect from 'vue-cool-select'
// import axios from 'axios'

window.axios = require('axios');
// import 'document-register-element/build/document-register-element'
// import 'leaflet/dist/leaflet.css';
// import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
// import vueNiceScrollbar from 'vue-nice-scrollbar'
// import * as VueGoogleMaps from 'vue2-google-maps';
// import PerfectScrollbar from 'vue2-perfect-scrollbar'
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

// Vue.component('l-map', LMap);
// Vue.component('l-tile-layer', LTileLayer);
// Vue.component('l-marker', LMarker);
// Vue.config.productionTip = false


// Vue.use(PerfectScrollbar)
// Vue.use(axios)
Vue.use(vueCustomElement)
Vue.use(VueSelect, {
  // theme: 'material-design' // or 'material-design'
})
// Vue.use(vueNiceScrollbar)
// Vue.use(VueGoogleMaps, {
//  load: {
//    key: 'AIzaSyAjMwIj5r41wAdLuR4NdIuk-rFpCwBM41E',
//    // key: 'AIzaSyATYezo93Nqw_oBn-7WoI2U9JxNlCZe_Vg',
//    libraries: 'places', // This is required if you use the Autocomplete plugin
//    // OR: libraries: 'places,drawing'
//    // OR: libraries: 'places,drawing,visualization'
//    // (as you require)
//  },
// });
Vue.customElement('bnp-vform', App)
